const moment = require('moment');

// Check whether a singe dateTime has been published and it's link should be displayed.
export function isPublished(dateTime) {
  moment.locale('fi');

  const currentDate = moment();
  dateTime = moment(dateTime);

  return (currentDate.isAfter(dateTime));
}

export function outputDateTime(writtenTimeString) {
  return moment(writtenTimeString).format("D.M.YYYY [klo] HH:mm");
}

// Think about it later that should each year be fetched separately
export function sortSummariesByYearAndSemester(passedSummaryArray) {
  // Destruct JSON array
  const {edges: summaryArray} = passedSummaryArray;
  
  const springMonths = [1, 2, 3, 4, 5, 6];
  const autumnMonths = [7, 8, 9, 10, 11, 12];
  
  // Add new years to this array
  const arrayOfYears = [
    2021,
    2020,
    2019,
    2018,
    2017
  ];
  
  const summariesByYearAndSemester = [];
  
  // Create empty json array for each year and it's two semesters
  for (let yearBuffer of arrayOfYears) {
    summariesByYearAndSemester.push({year: yearBuffer, spring: [], autumn: []});
  }
  
  // Add summaries to corresponding year and semester
  for (let {node: summary} of summaryArray) {
    for (let key in summariesByYearAndSemester) {
      if (parseInt(summary.frontmatter.year) === parseInt(summariesByYearAndSemester[key].year)) {
        if (autumnMonths.includes(parseInt(summary.frontmatter.month))) {
          summariesByYearAndSemester[key].autumn.push(summary);
        } else if (springMonths.includes(parseInt(summary.frontmatter.month))) {
          summariesByYearAndSemester[key].spring.push(summary);
        }
      }
    }
  }
  
  return summariesByYearAndSemester;
}

export function getFormattedDateTimeObject(dateTimeObject) {
  moment.locale('fi_FI');
  
  const formattedDateTimeObject = {};
  
  formattedDateTimeObject.date = moment(dateTimeObject).format("D.M.YYYY");
  formattedDateTimeObject.time = moment(dateTimeObject).format("[klo] HH:mm");
  
  switch (moment(dateTimeObject).format('ddd')) {
    case 'Mon':
      formattedDateTimeObject.weekdayInFinnish = "Ma";
      break;
    case 'Wed':
      formattedDateTimeObject.weekdayInFinnish = "Ke";
      break;
    default:
      formattedDateTimeObject.weekdayInFinnish = "Ti";
      break;
  }
  
  return formattedDateTimeObject;
}

export function compareWrittenAndModifiedDates(writtenOn, modifiedOn) {
  const formattedDateObject = {
    writtenPrefix: '',
    writtenTimeString: '',
    modifiedPrefix: '',
    modifiedTimeString: '',
    isModified: false,
  };

  formattedDateObject.writtenPrefix = 'Julkaistu';
  formattedDateObject.writtenTimeString = outputDateTime(writtenOn);
  
  if ( ! moment(writtenOn).isSame(modifiedOn)) {
    formattedDateObject.modifiedPrefix = 'Muokattu';
    formattedDateObject.modifiedTimeString = outputDateTime(modifiedOn);
    formattedDateObject.isModified = true;
  }
  
  return formattedDateObject;
}

export function isBrowser() {
  return typeof window !== 'undefined'
}
