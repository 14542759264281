/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { isBrowser } from './src/components/helpers.js'

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
    if (isBrowser) {  
      window.previousPath = location.pathname
      window.oneBeforePreviousPath = prevLocation ? prevLocation.pathname : null
    }
}
