exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arkisto-js": () => import("./../../../src/pages/arkisto.js" /* webpackChunkName: "component---src-pages-arkisto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-preview-template-js": () => import("./../../../src/templates/previewTemplate.js" /* webpackChunkName: "component---src-templates-preview-template-js" */),
  "component---src-templates-summary-template-js": () => import("./../../../src/templates/summaryTemplate.js" /* webpackChunkName: "component---src-templates-summary-template-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

